<template>
  <div class="bgImage centerAll">
    <div
      :class="{loginAndRegisterContainer: $screen.tablet,
               loginAndRegisterContainerPhone: !$screen.tablet}"
    >
      <a
        class="material-card-content centerAll"
        :href="$websiteUrl"
      >
        <img
          width="300px"
          src="@/assets/img/logoWhite.svg"
          alt="Boardgent logo"
        >
      </a>
      <card
        external-card="card container material-card card-form-container"
        internal-card="material-card-content"
        url-help=""
      >
        <section
          slot="header"
          class="modal-card-body"
        >
          <p
            style="margin-top: 12px;"
            class="title"
          >
            <span v-if="validateOtp">Two-factor authentication</span>
            <span v-else>Sign in to your account</span>
          </p>
          <form @submit.prevent="validateBeforeSubmit">
            <b-notification
              :active="error != undefined"
              :closable="false"
              class="errorBanner"
              type="is-danger"
            >
              {{ error != undefined ? error : '' }}
            </b-notification>
            <div v-show="!validateOtp">
              <b-field
                :type="errors.has('email') ? 'is-danger':''"
                :label="$adDomain ? 'Username' : 'Email'"
              >
                <b-input
                  id="email"
                  v-model="email"
                  v-validate="'required'"
                  name="email"
                  :type="$adDomain ? 'text' : 'email'"
                  :placeholder="$adDomain ? `Your username` : 'Your email'"
                  autocomplete="username"
                  @focus="updateValuesFromHtml"
                />
              </b-field>
              <span
                v-show="errors.has('email')"
                class="help is-danger"
              >{{ errors.first('email') }}</span>
              <b-field
                :type="errors.has('password') ? 'is-danger':''"
              >
                <template #label>
                  Password
                  <a
                    v-if="!$adDomain"
                    style="font-size: 14px;display:block;float:right;"
                    @click="resetPasswordOpen = true"
                  >Forgot your password?</a>
                </template>
                <b-input
                  id="password"
                  v-model="password"
                  v-validate="'required'"
                  name="password"
                  type="password"
                  :password-reveal="password != ''"
                  placeholder="Your password"
                  autocomplete="current-password"
                  @focus="updateValuesFromHtml"
                />
              </b-field>
              <span
                v-show="errors.has('password')"
                class="help is-danger"
              >{{ errors.first('password') }}</span>
            </div>
            <div v-show="validateOtp">
              <b-field
                :type="errors.has('otpCode') ? 'is-danger':''"
                label="Authentication code"
              >
                <b-input
                  v-model="otpCode"
                  v-validate="'required'"
                  name="otpCode"
                  placeholder="6-Digit code"
                  autocomplete="off"
                  type="text"
                  inputmode="numeric"
                  pattern="[0-9]*"
                />
              </b-field>
              <span
                v-show="errors.has('otpCode')"
                class="help is-danger"
              >{{ errors.first('otpCode') }}</span>
              <div style="display: flex;justify-content: center;">
                <b-icon
                  style="margin-top:4px;"
                  icon="cellphone"
                />
                <p>
                  Open the two-factor authentication app on your device to view your authentication
                  code and verify your identity.
                </p>
              </div>
            </div>
            <button
              type="submit"
              style="display: none;"
            >
              HIDDEN FOR LOGIN WITH ENTER
            </button>
          </form>
        </section>
        <div
          slot="footer"
          class="modal-card-body"
        >
          <button
            :class="{'is-loading': isLoading }"
            type="submit"
            class="button is-primary main-card-form-button"
            @click="validateBeforeSubmit"
          >
            {{ validateOtp ? 'Verify' : 'Continue' }}
          </button>
        </div>
      </card>
      <div v-if="!$adDomain">
        <b-button
          v-show="!validateOtp"
          expanded
          to="/register"
          class="button"
          tag="router-link"
          type="is-light"
        >
          Don't have an account? Sign up
        </b-button>
      </div>
      <b-modal :active.sync="resetPasswordOpen">
        <reset-password :current-email="email" />
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import resetPasswordModal from '@/components/authentication/SendResetPasswordModal.vue';
import card from '@/components/cross/Card.vue';

import ErrorMixin from '@/mixins/error';
import analyticsMixin from '@/mixins/analytics';

import vtulEnums from '../../../cross/index';

const { operationalUiErrors } = vtulEnums.enum.ui;
const { alertBox } = vtulEnums.enum.alert;

export default {
  name: 'Login',
  metaInfo: {
    title: 'Sign in',
  },
  components: {
    'reset-password': resetPasswordModal,
    card,
  },
  mixins: [ErrorMixin, analyticsMixin],
  data() {
    return {
      email: '',
      password: '',
      rememberMe: false, // Not working now, it remember always 24 hours
      isLoading: false,
      error: undefined,
      resetPasswordOpen: false,
      validateOtp: false,
      otpCode: '',
    };
  },
  methods: {
    ...mapActions('auth', ['authenticate']),
    ...mapActions('user', { findUser: 'find' }),
    updateValuesFromHtml() {
      // The @input and @change events are not called on some iOS browsers when tools like 1Password
      // fill out the login form, so the html has the right value but the v-model is empty
      // here we fill the v-model with the html value
      const emailInput = document.getElementById('email');
      const passwordInput = document.getElementById('password');
      this.email = emailInput ? emailInput.value : this.email;
      this.password = passwordInput ? passwordInput.value : this.password;
    },
    validateBeforeSubmit() {
      this.updateValuesFromHtml();
      if (this.validateOtp) {
        if (!this.otpCode || this.otpCode.length !== 6) {
          this.errors.add({
            field: 'otpCode',
            msg: this.otpCode ? 'The code must be of 6 characters' : 'The code is required',
          });
          return;
        }
        this.errors.remove('otpCode');
        this.validateOtpCode();
      } else {
        if (!this.email || !this.password) {
          if (!this.email) {
            this.errors.add({
              field: 'email',
              msg: 'The email is required',
            });
          }
          if (!this.password) {
            this.errors.add({
              field: 'password',
              msg: 'The password is required',
            });
          }
          return;
        }
        this.errors.remove('email');
        this.errors.remove('password');
        this.login();
      }
    },
    showError(error) {
      this.isLoading = false;
      this.error = error;
    },
    async login() {
      this.isLoading = true;
      this.error = null;
      try {
        let user;
        if (this.$isBgCloud) {
          user = await this.findUser({
            query: {
              $select: ['email'],
              email: this.email.toLowerCase(),
              $limit: 1,
              allowUnauthenticated: true,
            },
          });
        }
        if (!this.$isBgCloud || (user && user.data.length > 0)) {
          try {
            const authentication = await this.authenticate({
              strategy: this.$adDomain ? 'activeDirectory' : 'local',
              email: this.email.toLowerCase(),
              password: this.password,
            });
            if (authentication && authentication.requestOtp) {
              this.validateOtp = true;
              this.isLoading = false;
            } else {
              this.trackEvent(true, 'track', 'Log In', {
                email: this.email,
                otp: false,
              });
              if (this.$route.query && this.$route.query.returnUrl) {
                this.$router.push(this.$route.query.returnUrl);
              } else {
                this.$router.push('/');
              }
            }
          } catch (errorParam) {
            this.showError(errorParam.message);
          }
        } else {
          this.showError(operationalUiErrors.UNEXIST_ACCOUNT.value.message);
        }
      } catch (err) {
        this.isLoading = false;
        this.throwVtulError(operationalUiErrors.AUTHENTICATION_ERROR,
          alertBox.SNACKBAR.value, err);
      }
    },
    validateOtpCode() {
      this.isLoading = true;
      this.error = null;
      this.authenticate({
        strategy: this.$adDomain ? 'activeDirectory' : 'local',
        email: this.email.toLowerCase(),
        password: this.password,
        otpCode: this.otpCode,
      }).then(() => {
        this.trackEvent(true, 'track', 'Log In', {
          email: this.email,
          otp: true,
        });
        if (this.$route.query && this.$route.query.returnUrl) {
          this.$router.push(this.$route.query.returnUrl);
        } else {
          this.$router.push('/');
        }
      }).catch((errorParam) => {
        this.showError(errorParam.message);
        throw errorParam;
      });
    },
  },
};
</script>

<style>
.loginAndRegisterContainer{
  width:525px;
  padding-bottom: 1rem;
}

.loginAndRegisterContainerPhone{
  width:95%;
  padding-bottom: 1rem;
}

</style>

<style scoped>
.card-form-container {
  max-width: 750px;
}
</style>
