<template>
  <form
    action=""
    @submit.prevent="validateBeforeSubmit"
  >
    <div class="card modal-material-card modal-card-small">
      <div class="modal-card-header">
        <p>Reset your password</p>
      </div>
      <section class="modal-card-body">
        <p>
          Enter the email address associated with your account and we'll send
          you a link to reset your password.
        </p>
        <br>
        <b-notification
          :active="error != undefined"
          :closable="false"
          class="errorBanner"
          type="is-danger"
        >
          {{ error != undefined ? error.message : '' }}
        </b-notification>
        <br v-show="error != undefined">
        <b-field
          :type="errors.has('email') ? 'is-danger':''"
          label="Email"
        >
          <b-input
            v-model="recoverEmail"
            v-validate="'required'"
            name="email"
            type="email"
            placeholder="Your email"
          />
        </b-field>
        <span
          v-show="errors.has('email')"
          class="help is-danger"
        >{{ errors.first('email') }}</span>
      </section>
      <footer class="card-footer has-text-centered modal-card-footer">
        <a
          class="button force-right"
          type="button"
          @click="$parent.close()"
        >Return to sign in</a>
        <button
          :class="{'is-loading': isLoading }"
          type="submit"
          style="margin-left:6px"
          class="button is-primary"
        >
          Continue
        </button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapActions } from 'vuex';
import toastMessage from '@/helpers/toastMessage';
import vtulEnums from '../../../../cross/index';

const { managementAction } = vtulEnums.enum.authentication;

export default {
  name: 'SendResetPasswordModal',
  props: {
    currentEmail: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isLoading: false,
      recoverEmail: undefined,
      error: undefined,
    };
  },
  created() {
    this.recoverEmail = this.currentEmail || '';
  },
  methods: {
    ...mapActions('auth-management', { sendResetToken: 'create' }),
    validateBeforeSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.sendResetPassword(this.recoverEmail);
        }
      }).catch((error) => { throw new Error(error); });
    },
    sendResetPassword(email) {
      this.error = undefined;
      this.isLoading = true;
      this.sendResetToken({
        action: managementAction.SEND_RESET_PWD.value,
        email,
      }).then(() => {
        toastMessage.showSuccess('Check your email for a link to reset your password. If it doesn’t appear within a few minutes, check your spam folder.');
        this.$parent.close();
      }).catch((err) => {
        this.isLoading = false;
        this.error = err;
      });
    },
  },
};
</script>

<style scoped>
.errorBanner {
  margin:0px;
  padding:8px;
}
</style>
